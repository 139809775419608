import React from 'react'

const AdminReports = () => {
  return (
    <div>
      admin reports
    </div>
  )
}

export default AdminReports
