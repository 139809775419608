export const LAMBDA_URL =
  "https://ikaf5l6ufysc65bsxbnhb6hxzi0ykubx.lambda-url.ap-south-1.on.aws";
export const API_GATEWAY_URL =
  "https://0pwg9twyag.execute-api.ap-south-1.amazonaws.com";

export const url = LAMBDA_URL;

export const port = "8989";
export const version = "v1";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

//test
