export const COUNTRY_OPTIONS = [
  { label: "AFGHANISTAN", value: "Afghanistan" },
  { label: "INDIA", value: "India" },
  { label: "BANGLADESH", value: "Bangladesh" },
  { label: "PAKISTAN", value: "Pakistan" },
  { label: "SRILANKA", value: "Sri Lanka" },
  { label: "NEPAL", value: "Nepal" },
  { label: "BHUTAN", value: "Bhutan" },
  { label: "MALDIVES", value: "Maldives" },
  { label: "CHINA", value: "China" },
  { label: "JAPAN", value: "Japan" },
  { label: "MALAYSIA", value: "Malaysia" },
];
