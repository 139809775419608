import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#e6f7ff",
    color: "#000066",
    hoverBg: "#ccccff",
    activeBg: "#b3ccff",
  },
  adminSidebar: {
    bg: "black",
    color: "#eeeeee",
    hoverBg: "#0369a1",
    activeBg: "#1e253a",
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
};

export default colorConfigs;
